.radio-input {
    width:20px !important;
    margin-left: 15px !important;
}
.list-group-files-title {
    margin-top: 5px;
}

.list-group-files-ul {
    padding-top: 0;
}

.list-group-files-li {
    padding: 0 0 2px;
    /*margin-right: 20px;*/
}

.list-group-files-a {
    padding: 0;
    margin: 0 !important;
    height: 20px;
}
.list-group-files-a-del {
    padding-left: 10px !important;
    margin-bottom: 5px !important;
    margin-top: -10px !important;
    height: 20px;
    width: 40px;
}

.form-group-compact {
    margin-bottom: 0.5rem;
}
.form-group-tiny {
    margin-bottom: 0;
}

.form-group-normal-row {
    height: 77px;
}

.form-group-tiny-row {
    height: 47px;
}

.form-radio-group-row {
    height: 35px;
}

.dropzone-placeholder {
    margin-left: 5px;
    margin-bottom: 0;
}

.form-input-group {
    margin-bottom: 0 !important;
    /*height: 100%;*/
}

.form-input {
    height: 40px;
}

.form-input-textarea {
    /*height: 48px !important;*/
}

.form-checkbox {
    margin-left: 0 !important;
    width: 20px !important;
}

.form-checkbox-label {
    margin-left: 20px;
    margin-top: 2px;
}

.form-checkbox-group-element {
    margin-top: 7px;
}

.modal-ku {
    /*width: 750px !important;*/
    width: calc(100% - 100px) !important;
    /*margin: auto;*/
}

.edit-footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.edit-footer-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
}

.checkbox-btn-normal {
    color: #0065b1 !important;
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.form-field-label {
    margin-bottom: 2px !important;
    padding-bottom: 0 !important;
    font-weight: bold;
}