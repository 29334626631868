.left-position {
    left: -85px !important;
}

.front-filter {
    width: 250px;
    /*z-index: 100500 !important;*/
    /*position: absolute !important;*/
    /*left: 10px !important;*/
    /*top: 150px !important;*/
}

.front-filter-element {
    padding: 5px 24px;
}

.dropdown-item-tiny {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    height: 25px !important;
}

.header-month-year-select {
    /*border-left: none !important;*/
    /*border-right: none !important;*/
    /*border-top: none !important;*/
    border: none !important;
    /*border-bottom-color: #0065b1 !important;*/
    /*border-bottom-left-radius: unset !important;*/
    /*border-bottom-right-radius: unset !important;*/
    /*border-radius: 0 !important;*/
    /*-webkit-appearance: none !important;*/
}

.form-group-month {
    padding-right: 5px;
}

.title-element {
    padding-bottom: 3px;
}

.left-header {
    padding-left: 0 !important;
}