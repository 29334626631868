html {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
  /*font-style: normal;*/
}

/* Дичь для того, чтобы браузеры не подсвечивали input-ы желтым при автозаполнении */
input {outline: none;}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px #f9f9f9 inset;
  transition: background-color 5500s ease-in-out 0s;
}
