.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999 !important;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.confirmDialog {
    text-align: center;
    width: 500px;
    padding: 40px;
    /*background: #28bae6;*/
    background: #0090e6;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #fff;
}

.confirmDialog-btn-cancel {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    /*background: #D1D3D4;*/
    color: #fff;
    font-size: 14px;
}

.confirmDialog-btn-ok {
    width: 160px;
    padding: 10px;
    /*border: 1px solid #B40A1B;*/
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    /*background: #FF6A61;*/
    /*background: #ff7c76;*/
    /*color: #B40A1B;*/
    color: #fff;
    font-size: 14px;
}