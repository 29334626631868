.double-table-root-container {
    height: calc(100% - 125px);
}
.double-table-container {
    height: calc(100% - 50px);
}
.double-table-container-half {
    height: 50%;
}

.no-padding {
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.context-menu-button {
    height: 25px !important;
}
.fa-disabled {
    opacity: 0.6;
    /*color: #7c7f81;*/
    cursor: not-allowed;
}

.modal {
    display: block !important;
}
.modal-dialog {
    overflow-y: initial !important
}
.modal-body {
    overflow-y: visible;
}
.modal-error-container {
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 10px;
}
.modal-error-message {
    /*height: 44px;*/
    margin-bottom: 0;
}
.modal-body-scrollable {
    height: 80vh;
    overflow-y: auto;
}

.form-control, .custom-file-control,
.is-focused .form-control,
.is-focused .custom-file-control {
    background-image: linear-gradient(to top, #2e87fb 2px, rgba(0, 150, 136, 0) 2px), linear-gradient(to top, rgba(0, 0, 0, 0.26) 1px, transparent 1px);
}

.form-transparent-input {
    background-color: transparent !important;
    color: #000 !important;
}
.form-transparent-no-border-input {
    background-color: transparent !important;
    border: none !important;
    color: transparent !important;
}
.border-bottom {
    border: none;
    border-bottom: 1px solid #cccccc;
}
.main-text {
    color: #0065b1;
    background-color: transparent;
}
.red-text {
    color: #F6522E;
    /*color: #ff9582;*/
    background-color: transparent;
}
.inactive-text {
    color: #969ca1;
    background-color: transparent;

}
.toolbar-switch {
    margin-top: 8px;
}
.toolbar-left-text {
    display: inline-block;
    font-size: 11pt;
    margin-right: 8px;
    position: relative;
    top: -4px;
    /*line-height: 40px;*/
    /*margin-bottom: 10px;*/
    padding-bottom: 10px;
}
.toolbar-right-text {
    display: inline-block;
    font-size: 11pt;
    margin-left: 8px;
    margin-right: 14px;
    position: relative;
    top: -4px;
    /*margin-bottom: 100px;*/
}
.toolbar-label {
    line-height: 30px;
    height: 30px;
}
.toolbar-right {
    text-align: right;
    line-height: 30px;
    height: 30px;
    /*float: right;*/
}


.paging-summary {
    padding-top: 2px;
}

.astral-bg-primary {
    background-color: #0090e6 !important; }

.development-row {
    height: 200px;
}


.form-group select {
    -webkit-appearance: menulist-button;
}

.btn-link-href {
    /*height: 28px;*/
    height: 33px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
}


.btn-link-normal  {
    height: 33px;
    font-weight: 400;
    color: #0065b1;
    background-color: transparent;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
    margin-right: 0;
}

.dropdown-item-normal {
    color: #0065b1 !important;
    /*height: 24px !important;*/
    /*padding-top: 4px !important;*/
    /*padding-bottom: 4px !important;*/
}

.dropdown-item-left-padding-24 {
    padding-left: 18px !important;
}

.dropdown-no-bottom-margin {
    margin-bottom: 0 !important;
}

.btn-link-normal:hover {
    color: #0065b1;
    /*color: #009af0;*/
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
}

.btn-link-normal:focus {
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.btn-link-normal:active {
    color: #0065b1;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.btn-link-normal:disabled {
    color: #6c757d;
    pointer-events: none;
}

.hvr-underline-from-center-normal {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}
.hvr-underline-from-center-normal:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #0065b1;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.hvr-underline-from-center-normal:hover:before, .hvr-underline-from-center-normal:focus:before, .hvr-underline-from-center-normal:active:before {
    left: 20%;
    right: 20%;
}

.hvr-underline-from-center-nav {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}
.hvr-underline-from-center-nav:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #ffffff;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.hvr-underline-from-center-nav:hover:before, .hvr-underline-from-center-nav:focus:before, .hvr-underline-from-center-nav:active:before {
    left: 20%;
    right: 20%;
}

.hvr-underline-from-center-danger {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}
.hvr-underline-from-center-danger:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #cd3f44;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.hvr-underline-from-center-danger:hover:before, .hvr-underline-from-center-danger:focus:before, .hvr-underline-from-center-danger:active:before {
    left: 20%;
    right: 20%;
}

.glow-text-normal:hover {
    /* glow effect on hover */
    text-shadow: -1px 1px 10px #009af0, 1px -1px 10px #fff;
    -webkit-transition: text-shadow 250ms linear 0s;
    -moz-transition: text-shadow 250ms linear 0s;
    -o-transition: text-shadow 250ms linear 0s;
    transition: text-shadow 250ms linear 0s;
}
.glow-text-danger:hover {
    /* glow effect on hover */
    text-shadow: -1px 1px 10px #cd3f44, 1px -1px 10px #fff;
    -webkit-transition: text-shadow 250ms linear 0s;
    -moz-transition: text-shadow 250ms linear 0s;
    -o-transition: text-shadow 250ms linear 0s;
    transition: text-shadow 250ms linear 0s;
}

.btn-link-danger {
    font-weight: 400;
    color: #cd3f44;
    background-color: transparent;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
    margin-right: 0;
}

.btn-link-danger:hover {
    /*color: #ff383a;*/
    color: #cd3f44;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
}

.btn-link-danger:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none;
}

.btn-link-danger:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.font-roboto {
    font-family: 'Roboto', serif !important;
    font-weight: 300;
    /*font-style: italic;*/
}
.font-roboto-bold {
    font-family: 'Roboto', serif;
    font-weight: 500;
    /*font-style: italic;*/
}
.edit-view {
    background-color: #f9f9f9;
    margin-bottom: 0;
    /*width: 850px;*/
}

.toolbar-main {
    height: 50px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
}
.toolbar-empty {
    height: 25px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
}
.toolbar-main-group {
    height: 30px;
    width: 100%;
    margin: 10px 1px;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.125rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: 0;
    line-height: 1.25;
    color: #0065b1;
    background-color: transparent;
    border: 0 solid #dee2e6;
}
.page-link:hover {
    color: #0065b1;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 101, 177, 0.5);
}
.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #0065b1;
    border-color: #0065b1;
}

.save-btn {
    color: #0065b1;
    /*background-color: #007bff;*/
    /*border-color: #007bff;*/
}
.save-btn:hover {
    color: #0065b1;
    /*background-color: #007bff;*/
    /*border-color: #007bff;*/
}

.table-logo {
    display:block;
    /*margin:auto;*/
    margin-top: 10px;
}

.status-red {
    /*background-color: #ff4040*/
    /*background-color: #E85668*/
    background-color: #FF7272
}

.status-yellow {
    /*background-color: #ffbe40*/
    background-color: #FFD600
}

.status-green {
    /*background-color: #29922d*/
    background-color: #4BB462
}

.status-blue {
    background-color: #1771F1
}
.status-gray {
    background-color: #939598
}
.status-orange {
    background-color: #FE9E76
}

.status-alarm {
    /*color: red;*/
    color: #E20338;
    font-weight: 500;
}

.toolbar-element-size-200 {
    width: 250px !important;
}

.top-element {
    z-index: 600 !important;
}

.filter-btn {
    width: 100%;
    overflow-x: hidden;
}

.table-status-btn {
    height: 21px !important;
    /*font-weight: bold;*/
    font-weight: 500; /* Почти жирное начертание */
}
