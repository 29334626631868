@import "common.css";

.login-container {
    width: 250px;
}
.login-logo {
    display:block;
    margin:auto;
}
.login-btn {
    color: #0065b1;
    /*background-color: #007bff;*/
    /*border-color: #007bff;*/
}
.login-btn:hover {
    color: #0065b1;
    /*background-color: #0069d9;*/
    /*border-color: #0062cc;*/
}
.login-label {
    color: #0065b1;
    margin-top: 10px;
}

.text-error {
    /*color: #a21309;*/
    color: #ff0404;
}