.sub-active:hover {
    color: #0090e6 ;
}

.sub-active {
    color: #0090e6 ;
}

.dropdown-menu-above-table {
    z-index: 100 !important;
}

.navbar-above-table {
    z-index: 100 !important;
}